import { render, staticRenderFns } from "./header.component.vue?vue&type=template&id=0919f1a0&scoped=true&"
import script from "./header.component.ts?vue&type=script&lang=ts&"
export * from "./header.component.ts?vue&type=script&lang=ts&"
import style0 from "./header.component.scss?vue&type=style&index=0&id=0919f1a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0919f1a0",
  null
  
)

export default component.exports