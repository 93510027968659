import { render, staticRenderFns } from "./consult.view.vue?vue&type=template&id=00a00b10&scoped=true&"
import script from "./consult.view.ts?vue&type=script&lang=ts&"
export * from "./consult.view.ts?vue&type=script&lang=ts&"
import style0 from "./consult.view.scss?vue&type=style&index=0&id=00a00b10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a00b10",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
